import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';

import road_map from '../../Images/roadmap_line.png'
import balloon1 from '../../Images/balloon1.png'
import balloon2 from '../../Images/balloon2.png'
import roadmap_mobail_line from '../../Images/roadmap_mobail_line.png'
import balloon_mobail1 from '../../Images/balloon_mobail1.png'

const Section = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 160px; 
  padding-bottom: 320px;
  overflow: hidden;
  background-color: #fafafa;

`

const SectionBox = styled.div`
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1170px;
  width: 1170px;

  @media (max-width: 1300px) {
    width: 100%;
  };
`
const Contents = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 140px;

  @media (max-width: 1300px) {
    display: none;
  };

  & > img {
  }
  & > .content1 {
    display: flex;

    & > div {
      position: relative;
      width: 300px;
      height: 360px;

      & > h1, p {
        position: relative;
        z-index: 20;
      }

      & > img {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
      }

      & + div {
        margin-left: 48px;
      }
    }
  }

  & > .content2 {
    display: flex;
    margin-left: auto;
    margin-top: -10px;

    & > div {
      position: relative;
      width: 300px;
      height: 290px;

      & > h1 {
        padding-top: 10px;
      }

      & > h1, p {
        position: relative;
        z-index: 20;
      }

      & > img {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        transform: rotate( 180deg );
      }

      & + div {
        margin-left: 48px;
      }
    }
  }

  & > div > div {
    
    & > h1 {
      font-family: 'NEXON Lv1 Gothic Low OTF';
      font-weight: bold;
      margin-top: 40px;
      margin-left: 40px;
      font-size: 15px;
    }

    & > p {
      margin-top: 15px;
      margin-left: 40px;
      font-size: 13px;
      font-weight: 300;
      line-height: 140%;
      width: 250px;

      & + p {
        margin-top: 10px;
      }
    }
  }
`

const MobailContents = styled.div`
  margin-top: 120px;
  display: flex;
  justify-content: center;

  @media (min-width: 1300px) {
    display: none;
  };

  @media (max-width: 500px) {
    width: 90%;
  }

  & > img {
    height: 2132px;
  }


  & > div {
    margin-left: -5px;

    & > div + div {
      margin-top: 20px;
    }
    & > div {
      display: flex;
      position: relative;

      & > img {
        height: 340px;
        width: 280px;

        @media (max-width: 400px) {
          /* margin-left: auto; */
          width: 95%;
        }
      }

      & > div {
        position: absolute;
        top: 20px;
        left: 20px;
        width: 90%;

        @media (max-width: 500px) {
          width: 83%;
        }

        & > h1, p {
          margin-left: 20px;
          position: relative;
          z-index: 20;

          @media (max-width: 1300px) {
            /* margin-left: 50px; */
          }
        }

        & > h1 {
          padding-top: 20px;
          font-family: 'NEXON Lv1 Gothic Low OTF';
          font-weight: bold;
          font-size: 14px;
        }

        & > p {
        margin-top: 15px;
        font-size: 11px;
        font-weight: 300;
        line-height: 160%;
        width: 88%;

        & + p {
          margin-top: 10px;
          }
        }
      }
    }
  }
`
const TitleBox = styled.div`
  width: 970px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div:nth-child(1) > h2 {
    font-size: 47px;
    font-weight: bold;
    line-height: 110%;
  }
  & > div:nth-child(2) {
    margin-top: 20px;
    width: 240px;
    height: 4px;
    background-color: #000;
  }

  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 600px) {
    & > div:nth-child(1) > h2 {
      font-size: 47px;
    }
    & > div:nth-child(2) {
      width: 230px;
    }
  }
`

const RodmapContainer = ({ language }) => {
  return (
    <Section>
      <SectionBox>
        <TitleBox>
          <ScrollAnimation animateIn='fadeInUp'>
            <h2>ROADMAP</h2>
          </ScrollAnimation>
          <div />
        </TitleBox>
        <Contents>
          <div className="content1">
            <ScrollAnimation
              animateIn='pulse'
              initiallyVisible={true}>
              <h1>2021 H1</h1>
              {
                language === "한국어" ?
                  <>
                    <p>• 팀 빌딩</p>
                    <p>• 백서공개</p>
                    <p>• 홈페이지 개발</p>
                    <p>• 서비스 파트너십 구축</p>
                  </>
                  :
                  <>
                    <p>• Building team</p>
                    <p>• Opening white paper</p>
                    <p>• Developing website</p>
                    <p>• Building Service Partnership</p>
                  </>
              }
              <img alt="" src={balloon1} />
            </ScrollAnimation>
            <ScrollAnimation
              animateIn='pulse'
              duration={2}
              initiallyVisible={true}>
              <h1>2022 H1</h1>
              {
                language === "한국어" ?
                  <>
                    <p>• NFT 마켓 베타오픈</p>
                    <p>• 신탁자 및 미디어 파트너십 구축</p>
                    <p>• ARTS 토큰 거래소 상장</p>
                    <p>• 에이락 핀테크 리워드 오픈</p>
                    <p>• ARTS 토큰 리워드 시스템 개발 및 제휴 네트워크 구축</p>
                  </>
                  :
                  <>
                    <p>• Beta open of artwork NFT exchange</p>
                    <p>• Establishment of trustee and media partnerships</p>
                    <p>• To list on the exchange</p>
                    <p>• Open of A-fun reward system</p>
                    <p>• Development of ARTS token reward system and establishment of alliance network</p>
                  </>
              }
              <img alt="" src={balloon2} />
            </ScrollAnimation>
            <ScrollAnimation
              animateIn='pulse'
              duration={3}
              initiallyVisible={true}>
              <h1>2023 H1</h1>
              {
                language === "한국어" ?
                  <>
                    <p>• 글로벌 거래소 상장 (2차)</p>
                    <p>• 기술 및 서비스 고도화 (업데이트)</p>
                    <p>• 플랫폼 서버 인프라 확대</p>
                    <p>• Private Main net 오픈</p>
                    <p>• 글로벌 서비스 오픈</p>
                    <p>• 메타피스 프로젝트 개발</p>
                  </>
                  :
                  <>
                    <p>• To list on the global exchange (2st)</p>
                    <p>• To advance technology and service (updated)</p>
                    <p>• To expand the platform server infrastructure</p>
                    <p>• To open private main net</p>
                    <p>• To open global service prototype</p>
                    <p>• To develop Metapiece project</p>
                  </>
              }
              <img alt="" src={balloon1} />
            </ScrollAnimation>
          </div>
          <img alt="" src={road_map} />
          <div className="content2">
            <ScrollAnimation
              animateIn='pulse'
              duration={1.5}
              initiallyVisible={true}>
              <h1>2021 H2</h1>
              {
                language === "한국어" ?
                  <>
                    <p>• 기술개발 파트너십 구축</p>
                    <p>• 작품 및 콘텐츠 IP 확보</p>
                    <p>• 특허출원</p>
                    <p>• 시드투자 유치 및 법인 설립</p>
                    <p>• ARTS 토큰(ERC-20) 발행</p>
                    <p>• NFT 마켓 프로토타입 개발 </p>
                  </>
                  :
                  <>
                    <p>• Building technology partnership</p>
                    <p>• Securing work and content IPs</p>
                    <p>• Patent application</p>
                    <p>• Attracting seed investment and establishing a corporation</p>
                    <p>• Issuance of ARTS token (ERC20)</p>
                    <p>• To develop NFT Market Prototyoe</p>
                  </>
              }
              <img alt="" src={balloon2} />
            </ScrollAnimation>
            <ScrollAnimation
              animateIn='pulse'
              duration={2.5}
              initiallyVisible={true}>
              <h1>2022 H2</h1>
              {
                language === "한국어" ?
                  <>
                    <p>• 정식서비스 출시 (Full version)</p>
                    <p>• ARTS 토큰 리워드 시스템 오픈</p>
                    <p>• 시리즈 A 투자유치</p>
                    <p>• Private Main net 개발</p>
                    <p>• 글로벌 서비스 진출 준비</p>
                  </>
                  :
                  <>
                    <p>• To launch full service (Full version)</p>
                    <p>• Open of ARTS token reward system</p>
                    <p>• To attract Series A investment</p>
                    <p>• To develop Private Main net</p>
                    <p>• To prepare to enter global service</p>
                  </>
              }
              <img alt="" src={balloon1} />
            </ScrollAnimation>
            <ScrollAnimation
              animateIn='pulse'
              duration={3.5}
              initiallyVisible={true}>
              <h1>2023 H2</h1>
              {
                language === "한국어" ?
                  <>
                    <p>• 글로벌 아트셋 체인 협의체계 및 시범 서비스 추진</p>
                    <p>• 메타버스 플랫폼 제휴 계약</p>
                    <p>• 리워드 시스템 업그레이드</p>
                    <p>• 메타버스 가상 갤러리 구축</p>
                  </>
                  :
                  <>
                    <p>• To provide global Artxset chain consultation system and pilot service</p>
                    <p>• To make a metaverse platform partnership agreement</p>
                    <p>• To upgrade reward system</p>
                    <p>• To build metaverse virtual gallery</p>
                  </>
              }
              <img alt="" src={balloon2} />
            </ScrollAnimation>
          </div>
        </Contents>
        <MobailContents>
          <img alt="" src={roadmap_mobail_line} />
          <div>
            <ScrollAnimation
              animateIn='pulse'
              initiallyVisible={true}>
              <img alt="" src={balloon_mobail1} />
              <div>
                <h1>2021 H1</h1>
                {
                  language === "한국어" ?
                    <>
                      <p>• 팀 빌딩</p>
                      <p>• 백서공개</p>
                      <p>• 홈페이지 개발</p>
                    </>
                    :
                    <>
                      <p>• Building team</p>
                      <p>• Opening white paper</p>
                      <p>• Developing website</p>
                    </>
                }
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn='pulse'
              duration={1.5}
              initiallyVisible={true}>
              <img alt="" src={balloon_mobail1} />
              <div>
                <h1>2021 H2</h1>
                {
                  language === "한국어" ?
                    <>
                      <p>• 기술 및 서비스 파트너십 구축</p>
                      <p>• 시드투자 유치 및 법인 설립</p>
                      <p>• ARTS 토큰(ERC20) 발행</p>
                      <p>• 프로토타입 오픈 (51’Achieve)</p>
                      <p>• 거래소 상장 (1차)</p>
                      <p>• 핀테크 리워드 시스템 연동</p>
                    </>
                    :
                    <>
                      <p>• Building technology and service partnerships</p>
                      <p>• Attracting seed investment and establishing a corporation</p>
                      <p>• Issuance of ARTS token (ERC20)</p>
                      <p>• Prototype open (51'Achieve)</p>
                      <p>• Listing on the exchange (1st)</p>
                      <p>• Fin-Tech reward system linkage</p>
                    </>
                }
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn='pulse'
              duration={2}
              initiallyVisible={true}>
              <img alt="" src={balloon_mobail1} />
              <div>
                <h1>2022 H1</h1>
                {
                  language === "한국어" ?
                    <>
                      <p>• 예술품 NFT 거래소 베타오픈</p>
                      <p>• 신탁자 및 미디어 파트너십 구축</p>
                      <p>• ART SETS 프로토타입 개발 및 오픈</p>
                      <p>• ARTS 토큰 리워드 시스템 개발 및 제휴 네트워크 구축</p>
                      <p>• 금융기관 네트워크 구축</p>
                    </>
                    :
                    <>
                      <p>• Beta open of artwork NFT exchange</p>
                      <p>• Establishment of trustee and media partnerships</p>
                      <p>• Development and open of ART SETS prototype</p>
                      <p>• Development of ARTS token reward system and establishment of alliance network</p>
                      <p>• Establishment of a network of financial institutions</p>
                    </>
                }
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn='pulse'
              duration={2.5}
              initiallyVisible={true}>
              <img alt="" src={balloon_mobail1} />
              <div>
                <h1>2022 H2</h1>
                {
                  language === "한국어" ?
                    <>
                      <p>• ARTS 토큰 리워드 시스템 오픈</p>
                      <p>• 시리즈 A 투자유치</p>
                      <p>• 거버넌스 토큰 발행 (1차)</p>
                      <p>• 정식서비스 출시 (Full version)</p>
                      <p>• Private Main net 개발</p>
                      <p>• 글로벌 서비스 진출 준비</p>
                    </>
                    :
                    <>
                      <p>• To open ARTS token reward system</p>
                      <p>• To attract Series A investment</p>
                      <p>• To issue Governance Token (1st)</p>
                      <p>• To launch full service (Full version)</p>
                      <p>• To develop private main net</p>
                      <p>• To prepare to enter global service</p>
                    </>
                }
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn='pulse'
              duration={3}
              initiallyVisible={true}>
              <img alt="" src={balloon_mobail1} />
              <div>
                <h1>2023 H1</h1>
                {
                  language === "한국어" ?
                    <>
                      <p>• 거래소 상장 (2차)</p>
                      <p>• 기술 및 서비스 고도화 (업데이트)</p>
                      <p>• 플랫폼 서버 인프라 확대</p>
                      <p>• Private Main net 오픈</p>
                      <p>• 글로벌 서비스 프로토타입 오픈</p>
                      <p>• 메타피스 프로젝트 개발</p>
                    </>
                    :
                    <>
                      <p>• To list on the exchange (2st)</p>
                      <p>• To advance technology and service (updated)</p>
                      <p>• To expand the platform server infrastructure</p>
                      <p>• To open private main net</p>
                      <p>• To open global service prototype</p>
                      <p>• To develop Metaphys project</p>
                    </>
                }
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn='pulse'
              duration={3.5}
              initiallyVisible={true}>
              <img alt="" src={balloon_mobail1} />
              <div>
                <h1>2023 H2</h1>
                {
                  language === "한국어" ?
                    <>
                      <p>• 글로벌 아트셋 체인 협의체계 및 시범 서비스 추진</p>
                      <p>• 메타버스 플랫폼 제휴 계약</p>
                      <p>• 리워드 시스템 업그레이드</p>
                      <p>• 메타버스 가상 갤러리 구축</p>
                    </>
                    :
                    <>
                      <p>• To provide global Artxset chain consultation system and pilot service</p>
                      <p>• To make a metaverse platform partnership agreement</p>
                      <p>• To upgrade reward system</p>
                      <p>• To build metaverse virtual gallery</p>
                    </>
                }
              </div>
            </ScrollAnimation>
          </div>
        </MobailContents>
      </SectionBox>
    </Section>
  );
};

export default RodmapContainer;