import GlobalStyles from "./styles/GlobalStyles";
import { ParallaxProvider } from 'react-scroll-parallax';
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes";

function App() {
  return (
    <ParallaxProvider>
      <Router>
        <GlobalStyles />
        <Routes />
      </Router>
    </ParallaxProvider>
  );
}

export default App;
