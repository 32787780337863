import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';

import about_icon1 from '../../Images/about_icon1.svg';
import about_icon2 from '../../Images/about_icon2.svg';
import about_icon3 from '../../Images/about_icon3.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  padding-top: 240px; 
  padding-bottom: 300px;

  @media (max-width: 800px) {
    padding-top: 140px; 
    padding-bottom: 180px;
  };
`
const ContainerBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1170px;

  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 5%;
  }
`
const TitleBox = styled.div`
  width: 970px;

  & > div:nth-child(1) > h2 {
    font-size: 51px;
    font-weight: bold;
    line-height: 130%;
  }
  & > div:nth-child(2) {
    margin-top: 20px;
    width: 110px;
    height: 4px;
    background-color: #000;
  }
  & > div:nth-child(3) {
    & > h2:nth-child(1) {
      margin-top: 50px;
      line-height: 160%;
      font-size: 31px;
      font-weight: 600;
    }
    & > p:nth-child(2) {
      margin-top: 20px;
      line-height: 240%;
    }
  }

    @media (max-width: 1170px) {
      width: 100%;
      & > div:nth-child(1) > h2 {
      font-size: 45px;
    }
  };
`
const Display = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  opacity: 0.4;

  & > h2:nth-child(1) {
    font-size: 15px;
  }
  & > div {
    margin-top: 10px;
    height: 70px;
    width: 0.5px;
    background-color: #000;
  }
  & > h2:nth-child(3) {
    font-size: 11px;
    margin-top: 10px;
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }

  @media (max-width: 1200px) {
    display: none;
  };
`
const Line = styled.div`
  margin-top: 100px;
  width: 970px;
  border: 1px solid #E8E8E8;

  @media (max-width: 1170px) {
    width: 100%;
  };
`
const ContentsBox = styled.div`
  padding-top: 100px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center; 
  flex-wrap: wrap;

  & > div + div {
    margin-left: 73px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 330px;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 120px;
      height: 120px;
      border-radius: 120px;
      background: #000000;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    & > p:nth-child(2) {
      margin-top: 30px;
      font-size: 17px;
      font-weight: 700;
    }
    & > p:nth-child(3) {
      text-align: center;
      margin-top: 20px;
      font-size: 15px;
      font-weight: 100;
      line-height: 200%;
    }
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    padding-top: 40px;

    & > div {
      margin-top: 70px;
    }
    & > div + div {
      margin-left: 0;
    }
  };
`
const SubTitleBox = styled.div`
  width: 970px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > p:nth-child(1) {
      margin-top: 70px;
      line-height: 160%;
      font-size: 23px;
      font-weight: 700;
      text-align: center;
    }
    & > p:nth-child(2) {
      margin-top: 40px;
      line-height: 240%;
      text-align: center;
    }
  }

  @media (max-width: 1200px) {
    width: 100%;
  }
`

const AboutContainer = ({ language }) => {
  return (
    <Container>
      <ContainerBox>
        <TitleBox>
          <ScrollAnimation animateIn='fadeInUp'>
            <h2>What is ARTXSET</h2>
          </ScrollAnimation>
          <div />
          <ScrollAnimation animateIn='fadeIn' delay={500} duration="1.5">
            <h2>Blockchain Art-tech NFT Market</h2>
            {
              language === "한국어" ?
                <p>ARTXSET은 디지털 아트 뿐만 아니라 실제 예술품의 소유권 NFT 거래가 가능한 블록체인 아트테크 플랫폼입니다. 혁신적인 온라인 예술품 거래 프로세스, 블록체인 프로토콜 그리고 차별화된 스마트 컨트랙트를 기반으로 누구나 쉽고 안전하게 디지털 아트 및 실제 예술품의 소유권 NFT를 거래하고 소유권 지분에 따른 재판매 수익을 투명하게 배분 받을 수 있습니다.</p>
                :
                <p>ARTXSET is a blockchain art-tech platform that enables the NFT transaction of ownership of digital and Real  art. Based on innovative online art trading processes, blockchain protocols and differentiated smart contracts, anyone can easily and safely trade ownership NFTs of digital art and real art and receive transparent distribution of resale revenue from ownership shares.</p>
            }
          </ScrollAnimation>
        </TitleBox>
        <Display>
          <h2>01</h2>
          <div />
          <h2>What is ARTXSET</h2>
        </Display>
        <Line />
        <SubTitleBox>
          <ScrollAnimation animateIn='fadeIn' delay={500} duration="1.5">
            {
              language === "한국어" ?
                <>
                  <p>실제 예술품 NFT 거래 프로세스 및 스마트 컨트랙트</p>
                  <p>{`아트셋은 실제 작품의 NFT 거래가 가능한 특허기술인 <NFT를 활용한 실제 예술품 거래 시스템 및 운영 방법 / 출원번호 10-2021-0091529>을 통해 플랫폼에서 디지털 아트 뿐만 아니라 실제 작품의 NFT를 안전하게 거래할 수 있습니다.`}</p>
                </>
                :
                <>
                  <p>Real Art NFT Trading Process and Smart Contract</p>
                  <p>ARTXSET can safely trade NFTs with digital art on the platform through the actual art trading system and operation method using NFT, a patented technology that enables the real work to be traded.</p>
                </>
            }
          </ScrollAnimation>
        </SubTitleBox>
        <ContentsBox>
          <ScrollAnimation
            animateIn='fadeInUp'
          >
            <div>
              <img alt="" src={about_icon1} />
            </div>
            {
              language === "한국어" ?
                <>
                  <p>예술자산의 토큰화</p>
                  <p>판매자는 예술품 소유권을 분할하여 NFT를 판매할 수 있습니다. 판매자는 디지털 아트인지 실제 작품인지를 선택하여 그에 맞는 거래 프로세스와 프로토콜을 준수합니다.</p>
                </>
                :
                <>
                  <p>Tokenization of Artistic Assets</p>
                  <p>Seller may sell NFT by dividing the ownership of the artwork.Sellers follow the transaction process and protocols by choosing whether it is digital art or real work.</p>
                </>
            }
          </ScrollAnimation>
          <ScrollAnimation
            animateIn='fadeInUp'
            duration="1.25"
          >
            <div>
              <img alt="" src={about_icon2} style={{ marginRight: 13 }} />
            </div>
            {
              language === "한국어" ?
                <>
                  <p>온라인 경매 전환</p>
                  <p>작품 NFT 판매기간이 만료되면 해당 거래는 온라인 경매로 전환됩니다. 작품이 경매를 통해 신규 구매자(낙찰자)에게 판매되면 작품의 소유권은 100% 이전됩니다.</p>
                </>
                :
                <>
                  <p>Online auction conversion</p>
                  <p>When the NFT sales period expires, the transaction will be converted to an auction.When a work is sold to a new bidder, all ownership of the work is transferred to the bidder.</p>
                </>
            }
          </ScrollAnimation>
          <ScrollAnimation
            animateIn='fadeInUp'
            duration="1.5"
          >
            <div>
              <img alt="" src={about_icon3} />
            </div>
            {
              language === "한국어" ?
                <>
                  <p>NFT 스마트 컨트랙트</p>
                  <p>작품 소유권이 최종 낙찰자에게 이전되면 홀더들에게 NFT 청산을 공고합니다. 홀더는 플랫폼에서 NFT를 소각하고 수익을 배당 받을 수 있습니다.</p>
                </>
                :
                <>
                  <p>NFT Smart Contract</p>
                  <p>Once ownership is transferred to the final bidder, NFT liquidation will be announced to the holders.The holder can burn up NFTs on the platform and allocate revenue.</p>
                </>
            }
          </ScrollAnimation>
        </ContentsBox>
      </ContainerBox>
    </Container>
  )
}

export default AboutContainer;