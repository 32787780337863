import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import Fade from 'react-reveal/Fade';

import diagram1 from '../../Images/diagram1.png';
import diagram1_eng from '../../Images/diagram1_eng.png';
import diagram2 from '../../Images/diagram2.png';
import diagram3 from '../../Images/diagram3.png';
import diagram3_eng from '../../Images/diagram3_eng.png';
import m_diagram1 from '../../Images/m_diagram1.png';
import m_diagram1_eng from '../../Images/m_diagram1_eng.png';
import m_diagram2 from '../../Images/m_diagram2.png';
import m_diagram3 from '../../Images/m_diagram3.png';
import m_diagram3_eng from '../../Images/m_diagram3_eng.png';
import artxets_img from '../../Images/artxets_img.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  padding-top: 240px; 
  padding-bottom: 300px;

  @media (max-width: 800px) {
    padding-top: 140px; 
    padding-bottom: 180px;
  };
`
const ContainerBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1170px;

  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 5%;
  }
`
const TitleBox = styled.div`
  width: 970px;
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div:nth-child(1) > h2 {
    font-size: 67px;
    font-weight: bold;
    line-height: 110%;
    text-align: center;
  }
  & > div:nth-child(2) {
    margin-top: 20px;
    width: 480px;
    height: 4px;
    background-color: #000;
  }
  & > div:nth-child(3) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > p:nth-child(1) {
      margin-top: 70px;
      line-height: 160%;
      font-size: 23px;
      font-weight: 700;
      text-align: center;
    }
    & > p:nth-child(2) {
      margin-top: 40px;
      line-height: 240%;
      text-align: center;
    }
  }

  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 600px) {
    & > div:nth-child(1) > h2 {
      font-size: 53px;
    }
    & > div:nth-child(2) {
      width: 230px;
    }
  }
`

const Display = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  opacity: 0.4;

  & > h2:nth-child(1) {
    font-size: 15px;
  }
  & > div {
    margin-top: 10px;
    height: 70px;
    width: 0.5px;
    background-color: #000;
  }
  & > h2:nth-child(3) {
    font-size: 11px;
    margin-top: 10px;
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }

  @media (max-width: 1200px) {
    display: none;
  };
`
const Line = styled.div`
  margin-top: 120px;
  width: 970px;
  border: 1px solid #E8E8E8;

  @media (max-width: 1170px) {
    width: 100%;
  };
`
const ContentsBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const TopImg = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  & > div {
    & > h2 {
      text-align: center;
      font-size: 55px;
      font-weight: 400;
      line-height: 140%;
    }
  }

  @media (max-width: 670px) {
    & > div {
      & > h2 {
        font-size: 43px;
      }
    }
  }
`
const Dagram = styled.div`
  margin-top: 130px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  & > div {
    position: absolute;
    top: 0;
    left: 0;

    & > img {
      width: 100%;
    }
  }
  & > div:nth-child(1) {
    position: relative;
  }

  @media (max-width: 600px) {
    display: none;
  }
`
const MDagram = styled.div`
  margin-top: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 970px;

  & > div {
    position: absolute;
    top: 0;
    left: 0;

    & > img {
      width: 970px;
    }
  }
  & > div:nth-child(1) {
    position: relative;
  }

  @media (max-width: 1200px) {
    width: 100%;

    & > div {
      & > img {
        width: 100%;
      }
    }
  }
  @media (min-width: 600px) {
    display: none;
  }
`
const Button = styled.div`
  margin-top: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 270px;
  height: 54px;
  border: 1.5px solid #000;
  cursor: pointer;
  background-color: #000;

  &:hover {
    background-color: #fff;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    & > h2 {
      color: #000;
      font-weight: bold;
    }
  }

  & > h2 {
    font-size: 21px;
    color: #fff;
  }
`

const ArtxetsContainer = ({ language }) => {
  return (
    <Container>
      <ContainerBox>
        <TopImg>
          <ScrollAnimation animateIn='fadeIn'>
            <h2>Not everyone is an Art Expert</h2>
          </ScrollAnimation>
        </TopImg>
        <Display>
          <h2>03</h2>
          <div />
          <h2>Art-tech Protocol</h2>
        </Display>
        <Line />
        <ContentsBox>
          <TitleBox>
            <ScrollAnimation animateIn='fadeInUp'>
              <h2>Art-tech Protocol</h2>
            </ScrollAnimation>
            <div />
            <ScrollAnimation animateIn='fadeIn' delay={500} duration="1.5">
              {
                language === "한국어" ?
                  <>
                    <p>예술품 큐레이션 포트폴리오 펀딩 프로토콜</p>
                    <p>아트세츠는 전문가의 예술품 포트폴리오에 투자하는 파생상품입니다.<br />금융사, 미술관, 갤러리, 큐레이터, 딜러 등 플랫폼 신탁자는 작가, 작품의 장르 및 사조, 전시/페어 등을 테마로 주식시장의 ETF와 유사한 포트폴리오를 구성하고 작품 풀(Pool)의 펀드토큰을 발행하여 펀딩을 진행할 수 있습니다.<br />신탁자는 포트폴리오를 구성하여 신속하게 유동성을 확보하고 투자자는 작품 풀의 조각화된 암호자산을 이용하여 예술품 분산투자를 통해 리스크를 최소화할 수 있습니다.</p>
                  </>
                  :
                  <>
                    <p>Art Curation Portfolio Funding Protocol</p>
                    <p>Artxset is derivatives that invests in professional art portfolios.
                    <br />Platform truster such as financial companies, art museums, galleries, curators, and dealers can form portfolios similar to ETFs in the stock market under the themes of artists, genres and trends of works, exhibitions/pairs, etc., and carry out funding by issuing fund token of work pool.
                    <br />Truster can quickly secure liquidity through constructing a portfolio, and investors can minimize risk through diversified investment in art works using fragmented crypto assets in the art pool.</p>
                  </>
              }
            </ScrollAnimation>
          </TitleBox>
          <Dagram>
            <div>
              <Fade delay={1000}>
                <img alt="" src={diagram2} />
              </Fade>
            </div>
            <div>
              <Fade delay={2000}>
                {
                  language === "한국어" ?
                    <img alt="" src={diagram3} />
                    :
                    <img alt="" src={diagram3_eng} />
                }
              </Fade>
            </div>
            <div>
              <Fade>
                {
                  language === "한국어" ?
                    <img alt="" src={diagram1} />
                    :
                    <img alt="" src={diagram1_eng} />
                }
              </Fade>
            </div>
          </Dagram>
          <MDagram>
            <div>
              <Fade delay={1000}>
                <img alt="" src={m_diagram2} />
              </Fade>
            </div>
            <div>
              <Fade delay={2000}>
                {
                  language === "한국어" ?
                    <img alt="" src={m_diagram3} />
                    :
                    <img alt="" src={m_diagram3_eng} />
                }
              </Fade>
            </div>
            <div>
              <Fade>
                {
                  language === "한국어" ?
                    <img alt="" src={m_diagram1} />
                    :
                    <img alt="" src={m_diagram1_eng} />
                }
              </Fade>
            </div>
          </MDagram>
          <Button>
            <h2>Start Art Funding</h2>
          </Button>
        </ContentsBox>
      </ContainerBox>
    </Container>
  )
}

export default ArtxetsContainer;