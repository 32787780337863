import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// import telegram from '../../img/telegram.svg';

const Section = styled.div`
  position: fixed;
  display: ${props => props.pathname === "/clinics" ? "none" : "flex"};
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  height: 50px;
  width: 100%;
  z-index: 100;

  @media (max-width: 768px) {
    display: none;
  };
`

const BackgroundColor = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.9;
  z-index: 10;

  @media (max-width: 768px) {
   background-color: black;
   opacity: 1;
  };
`

const SectionBox = styled.div`
  z-index: 20;
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1360px;

`

const SelectBox = styled.div`
  margin-left: 120px;
  position: relative;
  cursor: pointer;

  @media (max-width: 768px) {
    display: none;
  };

  & > div:nth-child(1) {
    position: relative;
    display: flex;
    align-items: center;
    width: 100px;
    height: 24px;
    border-radius: 30px;
    background: #444;
    border: none;
    z-index: 30;

    &:after {
      position: absolute;
      content: "";
      top: 6px;
      right: 14px;
      width: 0;
      height: 0;
      border: 4px solid transparent;
      border-color: transparent transparent white transparent;
    }

    & > p {
      margin-left: 20px;
      font-size: 11px;
      color: white;
    }
  }
`

const PickSelectBox = styled.div`

  opacity: ${props => props.Translation ? "1" : "0"};
  z-index: ${props => props.Translation ? "50" : "10"};
  margin-top: ${props => props.Translation ? "-75px" : "200px"};
  position: absolute;
  top: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100px;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition-property: opacity, z-index, margin-top;
  transition-duration: 0.3s, 0.1s, 0.3s;

  & > p {
    line-height:32px;
    font-size: 12px;
    color: black;
    cursor: pointer;
    width: 100%;
    text-align: center;
  }
`

const TextBox = styled.div`
  color: white;
  margin-left: auto;
  margin-right: 120px;
  font-size: 12px;
  font-weight: 300;
  line-height: 22px;

  @media (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  };
`

const BottomBar = ({ language, Translation, TranslationHandler, KoreaLanguageHandler, EnglishLanguageHandler, pathname }) => {
  // const [PageBoolean, setPageBoolean] = useState(true);

  // useEffect(() => {
  //   document.addEventListener('scroll', () => {
  //     const ScrollY = window.pageYOffset
  //     console.log(ScrollY)
  //     if (ScrollY > 80) {
  //       setPageBoolean(true)
  //     } else {
  //       setPageBoolean(false);
  //     };
  //   });
  // }, []);

  return (
    <div>
      <Section pathname={pathname}>
        <SectionBox>
          <SelectBox>
            <div onClick={TranslationHandler}><p>{language}</p></div>
            <PickSelectBox Translation={Translation}>
              <p onClick={EnglishLanguageHandler}>English</p>
              <p onClick={KoreaLanguageHandler}>한국어</p>
            </PickSelectBox>
          </SelectBox>
          <TextBox>
            <p>All right Reserved. © 2021 GRIPFAN. Korea.</p>
          </TextBox>
        </SectionBox>
        <BackgroundColor />
      </Section>
    </div>
  );
};

export default BottomBar;