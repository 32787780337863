import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import Fade from 'react-reveal/Fade';

import cycles1 from '../../Images/cycles1.png';
import cycles2 from '../../Images/cycles2.png';
import cycles3 from '../../Images/cycles3.png';
import cycles1_eng from '../../Images/cycles1_eng.png';
import m_cycles1 from '../../Images/m_cycles1.png';
import m_cycles2 from '../../Images/m_cycles2.png';
import m_cycles3 from '../../Images/m_cycles3.png';
import m_cycles1_eng from '../../Images/m_cycles1_eng.png';
import m_cycles3_eng from '../../Images/m_cycles3_eng.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  padding-top: 240px; 
  padding-bottom: 300px;

  @media (max-width: 800px) {
    padding-top: 160px; 
    padding-bottom: 180px;
  };
`
const ContainerBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1170px;

  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 5%;
  }
`
const TitleBox = styled.div`
  width: 1070px;

  & > div:nth-child(1) > h2 {
    font-size: 70px;
    font-weight: bold;
    line-height: 140%;
    & > span {
      font-family: 'Abhaya Libre';
      font-weight: 700;
    }
  }
  & > div:nth-child(2) {
    margin-top: 20px;
    width: 119px;
    height: 4px;
    background-color: #000;
  }
  & > div:nth-child(3) {
    & > p:nth-child(1) {
      margin-top: 50px;
      line-height: 160%;
      font-size: 31px;
      font-weight: 600;
    }
    & > p:nth-child(2) {
      margin-top: 20px;
      line-height: 240%;
    }
  }

  @media (max-width: 1200px) {
    width: 100%;

    & > div:nth-child(1) > h2 {
      font-size: 47px;
    }
    & > div:nth-child(2) {
      width: 67px;
      height: 3px;
    }
  }
`
const ContentsBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Dagram = styled.div`
  margin-top: 150px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  & > div {
    position: absolute;
    top: 0;
    left: 0;

    & > img {
      width: 100%;
    }
  }
  & > div:nth-child(1) {
    position: relative;
  }

  @media (max-width: 600px) {
    display: none;
  }
`
const MDagram = styled.div`
  margin-top: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 970px;

  & > div {
    position: absolute;
    top: 0;
    left: 0;

    & > img {
      width: 970px;
    }
  }
  & > div:nth-child(1) {
    position: relative;
  }

  @media (max-width: 1200px) {
    width: 100%;

    & > div {
      & > img {
        width: 100%;
      }
    }
  }
  @media (min-width: 600px) {
    display: none;
  }
`
const Button = styled.div`
  margin-top: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 270px;
  height: 54px;
  border: 1.5px solid #000;
  cursor: pointer;
  background-color: #000;

  &:hover {
    background-color: #fff;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    & > h2 {
      color: #000;
      font-weight: bold;
    }
  }

  & > h2 {
    font-size: 21px;
    color: #fff;
  }
`

const CyclesContainer = ({ language }) => {
  return (
    <Container>
      <ContainerBox>
        <ContentsBox>
          <TitleBox>
            <ScrollAnimation animateIn='fadeInUp'>
              <h2>Art Transaction Cycles in ARTXSET</h2>
            </ScrollAnimation>
            <div />
            <ScrollAnimation animateIn='fadeIn' delay={500} duration="1.5">
              {
                language === "한국어" ?
                  <>
                    <p>아트테크에 최적화된 NFT 거래 사이클</p>
                    <p> 아트셋의 목표는 실제 예술품의 NFT 소유권을 판매하고 재판매 수익을 가장 빠르고 투명하게 배분할 수 있는 시스템을 개발하는 것입니다. 프로토콜은 기존 미술품 거래시장의 문제점을 개선하고 아트 NFT의 밸류에이션 프로세스를 실현할 수 있도록 설계되었습니다. 플랫폼은 소유권 NFT 판매부터 온라인 경매로 전환되는 일원화된 거래 프로세스를 통해 신속한 유동성 확보와 작품 판매를 보장할 수 있다.</p>
                  </>
                  :
                  <>
                    <p>NFT Trading Cycle Optimized for Art-tech</p>
                    <p> The goal of Artxset is to develop a system that can sell NFT ownership of real artworks and distribute resale profits in the fastest and most transparent way. The protocol is designed to improve the problems of the existing art trading market and realize the art NFT's valuation process. The platform can guarantee the secure of rapid liquidity and the sale of artworks through a unified trading process from sales of NFT ownership to online auction conversion.</p>
                  </>
              }
            </ScrollAnimation>
          </TitleBox>
          <Dagram>
            <div>
              <Fade delay={1000}>
                <img alt="" src={cycles2} />
              </Fade>
            </div>
            <div>
              <Fade delay={2000}>
                <img alt="" src={cycles3} />
              </Fade>
            </div>
            <div>
              <Fade>
                {
                  language === "한국어" ?
                  <img alt="" src={cycles1} />
                  :
                  <img alt="" src={cycles1_eng} />
                }
              </Fade>
            </div>
          </Dagram>
          <MDagram>
            <div>
              <Fade delay={1000}>
                <img alt="" src={m_cycles2} />
              </Fade>
            </div>
            <div>
              <Fade>
                {
                  language === "한국어" ?
                  <img alt="" src={m_cycles1} />
                  :
                  <img alt="" src={m_cycles1_eng} />
                }
              </Fade>
            </div>
            <div>
              <Fade delay={2000}>
                {
                  language === "한국어" ?
                  <img alt="" src={m_cycles3} />
                  :
                  <img alt="" src={m_cycles3_eng} />
                }
              </Fade>
            </div>
          </MDagram>
        </ContentsBox>
      </ContainerBox>
    </Container>
  )
}

export default CyclesContainer;