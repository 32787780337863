import React, { useState } from 'react';
import styled from 'styled-components';
import Header from './Header';
import Footer from './Footer';
import BottomBar from './BottomBar';

const Body = styled.div`
  min-height: 100vh;
`

const Template = ({ children, pathname, language, setLanguage }) => {
  const [SideMenu, setSideMenu] = useState(false);
  const [Translation, setTranslation] = useState(false);

  const setSideMenuHandler = () => {
    setSideMenu(!SideMenu);
  }
  const TranslationHandler = () => {
    setTranslation(!Translation);
  }
  const KoreaLanguageHandler = () => {
    setLanguage("한국어");
    setTranslation(!Translation);
  }
  const EnglishLanguageHandler = () => {
    setLanguage("English")
    setTranslation(!Translation);
  }

  return (
    <div>
      <Header
        SideMenu={SideMenu}
        setSideMenuHandler={setSideMenuHandler}
        language={language}
        KoreaLanguageHandler={KoreaLanguageHandler}
        EnglishLanguageHandler={EnglishLanguageHandler}
        pathname={pathname} 
        Translation={Translation}
        TranslationHandler={TranslationHandler}
      />
      <Body>
        {children}
      </Body>
      <BottomBar
        pathname={pathname}
        language={language}
        Translation={Translation}
        KoreaLanguageHandler={KoreaLanguageHandler}
        EnglishLanguageHandler={EnglishLanguageHandler}
        TranslationHandler={TranslationHandler}
      />
      <Footer
        pathname={pathname}
        language={language}
        Translation={Translation}
        KoreaLanguageHandler={KoreaLanguageHandler}
        EnglishLanguageHandler={EnglishLanguageHandler}
        TranslationHandler={TranslationHandler}
      />
    </div>
  );
};

export default Template;