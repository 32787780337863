import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';

import Partners1 from '../../Images/Partners/partners1.png'
import Partners2 from '../../Images/Partners/partners2.png'
import Partners3 from '../../Images/Partners/partners3.png'
import Partners4 from '../../Images/Partners/partners4.png'
import Partners5 from '../../Images/Partners/partners5.png'
import Partners6 from '../../Images/Partners/partners6.png'
import Partners7 from '../../Images/Partners/partners7.png'
import Partners8 from '../../Images/Partners/partners8.png'
import Partners9 from '../../Images/Partners/partners9.png'
import Partners10 from '../../Images/Partners/partners10.png'
import Partners11 from '../../Images/Partners/partners11.png'
import Partners12 from '../../Images/Partners/partners12.png'
import Partners13 from '../../Images/Partners/partners13.png'
import Partners14 from '../../Images/Partners/partners14.png'
import Partners15 from '../../Images/Partners/partners15.png'
import Partners16 from '../../Images/Partners/partners16.png'
import Partners17 from '../../Images/Partners/partners17.png'
import Partners18 from '../../Images/Partners/partners18.png'
import Partners19 from '../../Images/Partners/partners19.png'
import Partners20 from '../../Images/Partners/partners20.png'
import Partners21 from '../../Images/Partners/partners21.png'

const Section = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 180px; 
  overflow: hidden;
  padding-bottom: 280px;
  background-color: #fafafa;
`

const SectionBox = styled.div`
  position: relative;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1170px;
  height: 100%;

  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 20px;
  }
`

const ContentBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
  align-items: center;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 40px;
    margin-top: 140px;
    width: 160px;
    mix-blend-mode: darken;

    & > img {
      mix-blend-mode: darken;
      width: 170px;
      height: 100%;
    }
  }

  @media (max-width: 1300px) {
    & > div {
      margin: 0 30px;
      margin-top: 120px;
      width: calc(50% - 60px);

      & > img {
        width: 100%;
        height: 100%;
        max-width: 220px;
      }
    }
  }
`
const TitleBox = styled.div`
  width: 970px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div:nth-child(1) > h2 {
    font-size: 47px;
    font-weight: bold;
    line-height: 110%;
  }
  & > div:nth-child(2) {
    margin-top: 20px;
    width: 240px;
    height: 4px;
    background-color: #000;
  }

  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 600px) {
    & > div:nth-child(1) > h2 {
      font-size: 47px;
    }
    & > div:nth-child(2) {
      width: 230px;
    }
  }
`
const PartnersLine = styled.div`
  margin-top: 180px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;

  & > div {
    width: calc(50% - 80px);
    border-bottom: 1px solid #e8e8e8;
  }
  & > div:nth-child(1) {
    margin-right: auto;
  }
  & > div:nth-child(3) {
    margin-left: auto;
  }
  & > p {
    color: #888;
    font-size: 15px;
  }
`

const PartnersContainer = ({ language }) => {
  return (
    <Section>
      <SectionBox>
        <TitleBox>
          <ScrollAnimation animateIn='fadeInUp'>
            <h2>PARTNERS</h2>
          </ScrollAnimation>
          <div />
        </TitleBox>
        <ContentBox>
          <div>
            <img alt="" src={Partners5} />
          </div>
          <div>
            <img alt="" src={Partners2} />
          </div>
          <div>
            <img alt="" src={Partners3} />
          </div>
          <div>
            <img alt="" src={Partners9} />
          </div>
          {/* <div>
            <img alt="" src={Partners20} />
          </div>
          <div>
            <img alt="" src={Partners21} />
          </div> */}
        </ContentBox>
        <PartnersLine>
          <div />
          {
            language === "한국어" ?
              <p>협의 추진 중</p>
              :
              <p>under discussion</p>
          }
          <div />
        </PartnersLine>
        <ContentBox>
          <div>
            <img alt="" src={Partners4} />
          </div>
          <div>
            <img alt="" src={Partners1} />
          </div>
          {/* <div>
            <img alt="" src={Partners6} />
          </div> */}
          <div>
            <img alt="" src={Partners7} />
          </div>
          <div>
            <img alt="" src={Partners8} />
          </div>
          {/* <div>
            <img alt="" src={Partners10} />
          </div> */}
          <div>
            <img alt="" src={Partners11} />
          </div>
          <div>
            <img alt="" src={Partners12} />
          </div>
          <div>
            <img alt="" src={Partners13} />
          </div>
          <div>
            <img alt="" src={Partners14} />
          </div>
          <div>
            <img alt="" src={Partners15} />
          </div>
          <div>
            <img alt="" src={Partners16} />
          </div>
          <div>
            <img alt="" src={Partners17} />
          </div>
          <div>
            <img alt="" src={Partners18} />
          </div>
          <div>
            <img alt="" src={Partners19} />
          </div>
        </ContentBox>
      </SectionBox>
    </Section>
  )
}

export default PartnersContainer;