import React, { useState } from 'react';
import styled from 'styled-components';
import { PieChart, Pie, Sector } from 'recharts';
import Media from 'react-media';
import ScrollAnimation from 'react-animate-on-scroll';

const Section = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 80px; 
  overflow: hidden;
  padding-bottom: 260px;
`

const SectionBox = styled.div`
  position: relative;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1170px;
  height: 100%;

  @media (max-width: 1300px) {
    width: 100%;
    padding: 0 20px;
  }
`

const TotalToken = styled.div`
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  & > p {
    margin-top: 6px;
    font-weight: bold;
    font-size: 27px;
    line-height: 140%;
  }
  & > h1 {
    font-family: 'NEXON Lv1 Gothic Low OTF';
    margin-left: 14px;
    font-weight: bold;
    font-size: 38px;
    line-height: 140%;

    & > span {
      margin-top: 6px;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;

    & > h1 {
      margin-top: 10px;
      font-size: 8vw;
      font-family: 'NEXON Lv1 Gothic Low OTF';

      & > span {
        font-size: 5vw;
      }
    }
  }
`

const ContentBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 60px;

  & > div:nth-child(2){
    @media (max-width: 1220px) {
      margin-top: 50px;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > h1 {
      margin-top: 60px;
      font-family: 'NEXON Lv1 Gothic Low OTF';
      font-weight: bold;
      font-size: 27px;
      text-align: center;
      text-decoration-line: underline;
    }
  }

  text {
  }

  @media (max-width: 600px) {
    margin-top: 20px;
  }
`

const ChartData = styled.div`
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: 500px) {
    padding: 0 10px;
  };

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 20px;
    padding: 0 10px;

    @media (max-width: 500px) {
      width: 100%;
      margin-top: 10px;
      padding: 0 10px;
    };

    & > p {
      color: black;
      margin-left: 6px;
      font-size: 13px;
      line-height: 140%;
    }
  }

  & > div > div {
    flex-shrink: 0;
    flex-grow: 0;
    width: 14px;
    height: 14px;
    border-radius: 20px;
  }

  @media (max-width: 600px) {
    margin-top: -40px;
  }
`

const TokenBtn = styled.div`
  margin-top: 160px;
  
  & > a > div {
    width: 200px;
    height: 50px;
    border: 2px solid #000;
    border-radius: 50px;
    font-weight: bold;
    font-size: 16px;
    color: #000;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: #000;
      color: #fff;
    }
  }

  @media (max-width: 1300px) {
    width: 100%;
  }
`
const TitleBox = styled.div`
  width: 970px;
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div:nth-child(1) > h2 {
    font-size: 47px;
    font-weight: bold;
    line-height: 110%;
    text-align: center;
  }
  & > div:nth-child(2) {
    margin-top: 20px;
    width: 420px;
    height: 4px;
    background-color: #000;
  }
  & > div:nth-child(3) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > p:nth-child(1) {
      margin-top: 50px;
      line-height: 240%;
      text-align: center;
    }
  }

  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 600px) {
    & > div:nth-child(1) > h2 {
      font-size: 53px;
    }
    & > div:nth-child(2) {
      width: 230px;
    }
  }
`

const GraphContainer = ({ language }) => {
  const [data1] = useState(
    [
      { name: '30%', value: 30, fill: '#000', percent: 'Token Sale' },
      { name: '20%', value: 20, fill: '#333', percent: 'Eco System' },
      { name: '20%', value: 20, fill: '#555', percent: 'Team&Advisors&Partners' },
      { name: '2%', value: 2, fill: '#777', percent: 'Dontion' },
      { name: '28%', value: 28, fill: '#999', percent: 'Reserve' },
    ]
  );
  const [data2] = useState(
    [
      { name: '40%', value: 40, fill: '#000', percent: 'Development' },
      { name: '5%', value: 5, fill: '#333', percent: 'Legal' },
      { name: '10%', value: 10, fill: '#555', percent: 'Operation' },
      { name: '35%', value: 35, fill: '#777', percent: 'Marketing' },
      { name: '10%', value: 10, fill: '#999', percent: 'Reserve' },
    ]
  );
  const [activeIndex1, setActiveIndex1] = useState(0)
  const [activeIndex2, setActiveIndex2] = useState(0)

  const onPieEnter1 = (data, index) => {
    setActiveIndex1(index);
  };
  const onPieEnter2 = (data, index) => {
    setActiveIndex2(index);
  };

  return (
    <div id="IEO">
      <Section>
        <SectionBox>
          <TitleBox>
            <ScrollAnimation animateIn='fadeInUp'>
              <h2>ARTS Token Issuance</h2>
            </ScrollAnimation>
            <div />
            <ScrollAnimation animateIn='fadeIn' delay={500} duration="1.5">
              {
                language === "한국어" ?
                  <p>ARTS 토큰(ERC-20)은 유틸리티 토큰으로 플랫폼의 모든 서비스에 사용된다. 스마트 컨트랙트를 통해 이더리움 메인넷 또는 사이드체인에서 발행된 토큰과의 상호 전환을 보장한다.</p>
                  :
                  <p>The ARTS token can be used for all services of platform. Reciprocal conversion with tokens issued on the Ethereum main-net or Side-chain through Smart Contract is guaranteed.</p>
              }
            </ScrollAnimation>
          </TitleBox>
          <TotalToken>
            <p>Total</p>
            <h1>2,000,000,000 <span>ARTS</span></h1>
          </TotalToken>
          <ContentBox>
            <div>
              <h1>Allocation</h1>
              <PieChart width={585} height={400}>
                <Pie
                  activeIndex={activeIndex1}
                  activeShape={renderActiveShape1}
                  data={data1}
                  innerRadius={100}
                  outerRadius={130}
                  fill="#000"
                  dataKey="value"
                  onMouseEnter={onPieEnter1}
                />
              </PieChart>
              <ChartData>
                <div>
                  <div style={{ backgroundColor: "#000", wordBreak: "normal" }} />
                  <p>Token Sale 30%</p>
                </div>
                <div>
                  <div style={{ backgroundColor: "#333" }} />
                  <p>Reserve 28%</p>
                </div>
                <div>
                  <div style={{ backgroundColor: "#555" }} />
                  <p>Dontion 2%</p>
                </div>
                <div>
                  <div style={{ backgroundColor: "#777" }} />
                  <p>Team&Advisors&Partners 20%</p>
                </div>
                <div>
                  <div style={{ backgroundColor: "#999" }} />
                  <p>Eco System 20%</p>
                </div>
              </ChartData>
            </div>
            <div>
              <h1>Use of Proceed</h1>
              <PieChart width={585} height={400}>
                <Pie
                  activeIndex={activeIndex2}
                  activeShape={renderActiveShape2}
                  data={data2}
                  innerRadius={100}
                  outerRadius={130}
                  fill="#000"
                  dataKey="value"
                  onMouseEnter={onPieEnter2}
                />
              </PieChart>
              <ChartData>
                <div>
                  <div style={{ backgroundColor: "#000", wordBreak: "normal" }} />
                  <p>Development 40%</p>
                </div>
                <div>
                  <div style={{ backgroundColor: "#333" }} />
                  <p>Reserve 10%</p>
                </div>
                <div>
                  <div style={{ backgroundColor: "#555" }} />
                  <p>Marketing 35%</p>
                </div>
                <div>
                  <div style={{ backgroundColor: "#777" }} />
                  <p>Operation 10%</p>
                </div>
                <div>
                  <div style={{ backgroundColor: "#999" }} />
                  <p>Legal 5%</p>
                </div>
              </ChartData>
            </div>
            {/* <TokenBtn>
              <a href={""} target="_blank" rel="noopener noreferrer">
                <div><p>TOKEN SALE</p></div>
              </a>
            </TokenBtn> */}
          </ContentBox>
        </SectionBox>
      </Section>
    </div>
  )
}

const renderActiveShape1 = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
    fill, payload, percent, value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <Media queries={{ small: { maxWidth: 600 } }}>
        {matches =>
          matches.small ? (
            <>
              <text style={{ fontSize: 24 }} x={cx} y={cy} dy={8} textAnchor="middle" fill={"#000"}>{payload.name}</text>
              <text style={{ fontSize: "12px", paddingTop: "60px" }} x={cx} y={cy} dy={32} textAnchor="middle" fill="#000">{`${percent}`}</text>
            </>
          ) : (
              <text style={{ fontSize: 24 }} x={cx} y={cy} dy={8} textAnchor="middle" fill={"#000"}>{payload.name}</text>
            )
        }
      </Media>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <Media queries={{ small: { maxWidth: 600 } }}>
        {matches =>
          !matches.small && (
            <>
              <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
              <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
              <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value}%`}</text>
              <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#888" >
                {`${percent}`}
              </text>
            </>
          )
        }
      </Media>
    </g>
  );
};

const renderActiveShape2 = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
    fill, payload, percent, value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <Media queries={{ small: { maxWidth: 600 } }}>
        {matches =>
          matches.small ? (
            <>
              <text style={{ fontSize: 24 }} x={cx} y={cy} dy={8} textAnchor="middle" fill={"#000"}>{payload.name}</text>
              <text style={{ fontSize: "12px", paddingTop: "60px" }} x={cx} y={cy} dy={32} textAnchor="middle" fill="#000">{`${percent}`}</text>
            </>
          ) : (
              <text style={{ fontSize: 24 }} x={cx} y={cy} dy={8} textAnchor="middle" fill={"#000"}>{payload.name}</text>
            )
        }
      </Media>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <Media queries={{ small: { maxWidth: 600 } }}>
        {matches =>
          !matches.small && (
            <>
              <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
              <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
              <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value}%`}</text>
              <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#888">
                {`${percent}`}
              </text>
            </>
          )
        }
      </Media>
    </g>
  );
};

export default GraphContainer;