import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';

import beyond_bg from '../../Images/beyond_bg.png';

const Section = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  /* padding-top: 375px; 
  padding-bottom: 375px; */
  min-height: 1000px;
  background-color: #000;
  background-image: url(${beyond_bg});
  background-size: cover;
  background-position: center;

  /* @media (max-width: 800px) {
    padding-top: 120px; 
    padding-bottom: 300px;
  }; */
`
const ContentsBox = styled.div`
  position: relative;
  z-index: 100;
  width: 1280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1280px) {
    width: 100%;
    padding: 0 5%;
  };
`
const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div {
    & > h2 {
      font-size: 70px;
      color: #fff;
      font-weight: 500;
      text-align: center;
      line-height: 120%;
    }
    & > p{
      margin-top: 60px;
      font-size: 17px;
      width: 970px;
      color: #fff;
      line-height: 200%;
      text-align: center;
      font-weight: 100;
    }
  }
 
  @media (max-width: 1010px) {
    & > div {
      & > p {
        width: 100%;
      }
    }
  };
  @media (max-width: 600px) {
    & > div > h2 {
      font-size: 63px;
    }
  }
`
const BeyondContainer = ({ language }) => {

  return (
    <Section id='HOME'>
      <ContentsBox>
        <TitleBox>
          <ScrollAnimation animateIn='fadeInUp'>
            <h2>Beyond the Authentic</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeIn' delay={500} duration="1.5">
            {
              language === "한국어" ?
                <p>아트셋은 블록체인, 미디어, 아트, IT 전문가들이 모여 기존 온라인 미술품 거래를 개선할 수 있는 블록체인 아트테크 프로토콜을 개발하는데 집중합니다. 이를 통해 디지털 아트의 NFT 밸류에이션 프로세스 및 아카이브를 구축할 수 있습니다.<br />이는 향후 디지털 금융, 메타버스 플랫폼과의 융복합을 통해 진정한 아트라이프를 실현하는데 이용됩니다.</p>
                :
                <p>Experts of blockchain, media, art, and IT gather in Artxset and focus on developing blockchain Art-Tech protocol to improve existing online art trading. This allows us to build a digital art’s NFT valuation process and archive.
                <br />They are used to realize true art-life through convergence with digital finance and metaverse platforms.</p>
            }
          </ScrollAnimation>
        </TitleBox>
      </ContentsBox>
    </Section>
  );
};

export default BeyondContainer;