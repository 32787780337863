import React from 'react';
import styled from 'styled-components';

import ModalLogo from '../../Images/modal_logo.png';

const Container = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: ${props => props.modal ? "flex" : "none"};
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`

const Modal = styled.div`
  position: relative;
  z-index: 200;
  margin-bottom: 80px;
  background-color: #fff;
  width: 400px;
  height: 320px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 20px;
  
  & > img {
    margin-top: auto;
    width: 50px;
  }
  & > h1 {
    margin-top: 20px;
    font-size: 19px;
    font-weight: 500;
  }
  & > p {
    margin-top: 10px;
    font-size: 15px;
    line-height: 140%;
    text-align: center;
    font-weight: 100;
  }
  & > button {
    margin-top: auto;
    margin-bottom: 20px;
    height: 40px;
    width: 100%;
    border-radius: 5px;
    background-color: #000;
    font-size: 15px;
    color: #fff;
    cursor: pointer;
  }

  @media (max-width: 400px) {
    width: 100%;
  }
`

const BgBlack = styled.div`
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.4;
`


export default ({ modal }) => {
  return (
    <Container modal={modal.value}>
      <Modal>
        <img alt="" src={ModalLogo} />
        <h1>이메일 등록이 완료되었습니다.</h1>
        <p>새로운 Artxset의 소식을 이제 이메일을 통해<br/>받아보실 수 있습니다.</p>
        <button onClick={() => { modal.setValue(!modal.value)}}>확인</button>
      </Modal>
      <BgBlack />
    </Container>
  )
}