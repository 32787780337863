import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import Nexon from './fonts/NEXON Lv1 Gothic Low OTF.woff';

export default createGlobalStyle`
    ${reset};
    @import url('https://fonts.googleapis.com/css?family=Noto+Sans+KR:100,300,400,500,700,900|Roboto:100,300,400,500,700,900&display=swap');
    @import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700,900|Noto+Sans+SC:100,300,400,500,700,900|Noto+Sans+TC:100,300,400,500,700,900&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;500;600;700&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Sen:wght@400;700;800&display=swap"');
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap"');
    @font-face {
        font-family: 'NEXON Lv1 Gothic Low OTF Light';
        src: url(${Nexon}) format('woff');
    }

    * {
        box-sizing:border-box;
        margin: 0;
        padding: 0;
    }

    body {
        font-family: 'Noto Sans KR', 'Noto Sans JP', 'Noto Sans SC', 'Noto Sans TC', sans-serif;
        word-break: keep-all;
        word-wrap: break-word;
    }
    h1 {
        font-family: 'Noto Sans KR', 'Noto Sans JP', 'Noto Sans SC', 'Noto Sans TC', sans-serif;
    }
    h2 {
        font-family: 'Cormorant Garamond', 'Noto Sans KR', 'Noto Sans JP', 'Noto Sans SC', 'Noto Sans TC', sans-serif;
    }
    p {
        font-weight: 200;
        font-family: 'NEXON Lv1 Gothic Low OTF', 'Roboto';
    }

    a {
        text-decoration:none;
    }
    
    button {
        outline: none;
        border: none;
    }

    input:focus{
        outline:none;
    }
`;