import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';

import HeaderLogoImgWhite from './Images/logo_white.svg'
import home_icon from './Images/home_icon.svg'

let ScrollLink = Scroll.Link;

const Section = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 380px;
  overflow: hidden;
  background-color: #000;
`
const ContentsBox = styled.div`
  position: relative;
  z-index: 100;
  width: 1280px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 70px;

  @media (max-width: 1280px) {
    width: 100%;
    justify-content: flex-start;
    padding: 0 20px;
    padding-top: 70px;
  };
`
const Logo = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;

  & a > img {
    height: 30px;
  }

  & > p {
    margin-top: 40px;
    color: #fff;
    font-size: 13px;
    font-weight: 100;
    line-height: 180%;
  } 
`
const Homepage = styled.div`
  margin-top: 10px;
   & > a {
    padding: 3px 10px;
    display: inline-block;
    background-color: #222;

    & > img {
      margin-top: 3px;
      width: 12px;
      height: auto;
    }

    & > p {
      margin-left: 10px;
      float: right;
      color: #fff;
      font-size: 13px;
      font-weight: 100;
      line-height: 180%;
    }
  }
`

const TopMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: auto;

  & > a {
    margin-left: 50px;
    cursor: pointer;

    & > a > h2 {
      color: #fff;
      font-weight: 300;
      font-size: 16px;
      font-weight: 400;

      & > span {
        font-family: 'Abhaya Libre';
        font-weight: 400;
      }
    }
    
    &:first-child {
      margin-left: 0;
    }
  }

  & > .active {
    
    & > a > h2 {
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      font-weight: 400;

      & > span {
        font-family: 'Abhaya Libre';
        font-weight: 400;
      }
    }
  }

  & > a > div:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 32px;
    border: 1px solid black;
    border-radius: 34px;
    font-size: 12px;
    font-weight: 300;

    & p {
      color: black;
    }
  }

  & > a > div:last-child:hover {
    background-color: black;

    & p {
      color: white;
    }
  }

  @media (max-width: 800px) {
    display: none;
  };
`

const Footer = ({ language }) => {

  return (
    <Section>
      <ContentsBox>
        <Logo>
          <ScrollLink
            activeClass="active"
            to="HOME"
            spy={true}
            smooth={true}
            offset={-60}
            duration={500}
          >
            <Link to="/">
              <img alt="" src={HeaderLogoImgWhite} />
            </Link>
          </ScrollLink>
          {
            language === "한국어" ?
            <>
              <p>GRIPFAN Inc.
              <br />서울시 서울시 강남구 테헤란로 431, 5층
              <br />T. 02-6204-2920 / F. 02-6302-7014 / E. gripfan.kr@gmail.com
              </p>
            </>
            :
            <>
              <p>GRIPFAN Inc.
              <br />5F, 431, Teheran-ro, Gangnam-gu, Seoul, Republic of Korea
              <br />T. 02-6204-2920 / F. 02-6302-7014 / E. gripfan.kr@gmail.com
              </p>
            </>
          }
          <Homepage>
            <a href="http://gripfan.co.kr/" target="_blank">
              <img alt="" src={home_icon}/>
              <p>COMPANY</p>
            </a>
          </Homepage>
        </Logo>
        <TopMenu>
          <ScrollLink
            activeClass="active"
            to="ABOUT"
            spy={true}
            smooth={true}
            offset={-60}
            duration={500}
          >
            <Link to="/">
              <h2>ABOUT</h2>
            </Link>
          </ScrollLink>
          <ScrollLink
            activeClass="active"
            to="PROTOCOL"
            spy={true}
            smooth={true}
            offset={-60}
            duration={500}
          >
            <Link to="/">
              <h2>PROTOCOL</h2>
            </Link>
          </ScrollLink>
          <ScrollLink
            activeClass="active"
            to="ARTSETS"
            spy={true}
            smooth={true}
            offset={-60}
            duration={500}
          >
            <Link to="/">
              <h2>ARTSETS</h2>
            </Link>
          </ScrollLink>
          <ScrollLink
            activeClass="active"
            to="ART-TECH"
            spy={true}
            smooth={true}
            offset={-60}
            duration={500}
          >
            <Link to="/">
              <h2>ART-TECH</h2>
            </Link>
          </ScrollLink>
          <ScrollLink
            activeClass="active"
            to="VISION"
            spy={true}
            smooth={true}
            offset={-60}
            duration={500}
          >
            <Link to="/">
              <h2>VISION</h2>
            </Link>
          </ScrollLink>
        </TopMenu>
      </ContentsBox>
    </Section>
  );
};

export default Footer;