import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';

import change_icon1 from '../../Images/change_icon1.svg';
import change_icon2 from '../../Images/change_icon2.svg';
import change_icon3 from '../../Images/change_icon3.svg';
import change_icon4 from '../../Images/change_icon4.svg';
import change_bg from '../../Images/change_bg.png';
import change_phone from '../../Images/change_phone.png';

const Section = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 240px; 
  padding-bottom: 300px;
  background-color: #000;
  background-image: url(${change_bg});
  background-size: cover;
  background-position: center;

  @media (max-width: 800px) {
    padding-top: 20px; 
    padding-bottom: 200px;
  };
`
const ContentsBox = styled.div`
  position: relative;
  z-index: 100;
  width: 1280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  & > img {
    width: 221px;
  }

  & > h2 {
    text-align: center;
    margin-top: 50px;
    font-weight: bold;
    font-size: 51px;
    line-height: 140%;
  }

  @media (max-width: 1280px) {
    width: 100%;
    padding: 0 5%;
  };

  @media (max-width: 768px) {
    margin-top: 140px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > h2 {
      width: 400px;
      text-align: center;
    }
  };
`
const TitleBox = styled.div`
  width: 1070px;

  & > div:nth-child(1) > h2 {
    font-size: 70px;
    font-weight: bold;
    line-height: 140%;
    color: #fff;
    & > span {
      font-family: 'Abhaya Libre';
      font-weight: 700;
    }
  }
  & > div:nth-child(2) {
    margin-top: 20px;
    width: 176px;
    height: 4px;
    background-color: #fff;
  }
  & > div:nth-child(3) {
    & > p:nth-child(1) {
      margin-top: 50px;
      line-height: 160%;
      font-size: 31px;
      font-weight: 600;
      color: #fff;
    }
    & > p:nth-child(2) {
      margin-top: 20px;
      line-height: 240%;
      color: #fff;
    }
  }

  @media (max-width: 1200px) {
    width: 100%;

    & > div:nth-child(1) > h2 {
      font-size: 47px;
    }
    & > div:nth-child(2) {
      width: 67px;
      height: 3px;
    }
  }
`
const Content = styled.div`
  padding-top: 100px;

  & > div + div {
    margin-top: 20px;
  }
  & > div {
    width: 1170px;
    height: 220px;
    background-color: #fff;
    border-radius: 180px;
    display: flex;
    align-items: center;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
      margin-left: 5px;
      width: 200px;
      height: 200px;
      background-color: #000;
      border-radius: 160px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      & > p {
        font-size: 17px;
        color: #fff;
        text-align: center;
        line-height: 160%;
      }
    }
    & > p {
      margin-left: 35px;
      width: 840px;
      line-height: 200%;
      font-size: 17px;
    }
  }

  @media (max-width: 1200px) {
    width: 100%;

    & > div {
      width: 100%;
      height: auto;
      background-color: #fff;
      border-radius: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
        margin-left: 5px;
        width: calc(100% - 10px);
        height: auto;
        padding: 12px 0;
        background-color: #000;
        border-radius: 0px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
      & > p {
        margin-top: 20px;
        margin-bottom: 50px;
        margin-left: 20px;
        margin-right: 20px;
        width: auto;
        line-height: 200%;
        font-size: 17px;
      }
    }
  }
`
const Display = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  opacity: 0.4;

  & > h2:nth-child(1) {
    font-size: 15px;
    color: #fff;
  }
  & > div {
    margin-top: 10px;
    height: 70px;
    width: 0.5px;
    background-color: #fff;
  }
  & > h2:nth-child(3) {
    font-size: 11px;
    margin-top: 10px;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    color: #fff;
  }

  @media (max-width: 1200px) {
    display: none;
  };
`
const ChangeContent = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 1200px) {
    flex-direction: column;
  };
`
const ChangeTextBox = styled.div`
  margin-top: 100px;

  & > div + div {
    margin-top: 60px;
  }

  & > div {
    width: 500px;

    & > div {
      display: flex;
      align-items: center;
      & > p {
        margin-left: 15px;
        font-size: 17px;
        line-height: 140%;
        font-weight: bold;
        color: #fff;
      }
    }
    & > p {
      margin-top: 20px;
      color: #fff;
      font-size: 15px;
      line-height: 200%;
      font-weight: 300;
    }
  }

  @media (max-width: 1200px) {
    width: 100%;

    & > div {
      width: 100%;
    }
  };
`
const ChangeImg = styled.div`
  margin-left: auto;
  margin-top: 100px;

  & > img {
    width: 290px;
  }

  @media (max-width: 1200px) {
    display: none;
  };
`
const MChangeImg = styled.div`
  margin-top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    width: 290px;
  }

  @media (min-width: 1200px) {
    display: none;
  };
  @media (max-width: 330px) {
    width: 80%;
  };
`

const TokenContainer = ({ language }) => {

  return (
    <Section id='HOME'>
      <ContentsBox>
        <TitleBox>
          <ScrollAnimation animateIn='fadeInUp'>
            <h2>Token Economy for Arttech</h2>
          </ScrollAnimation>
          <div />
          <ScrollAnimation animateIn='fadeIn' delay={500} duration="1.5">
            {
              language === "한국어" ?
                <>
                  <p>플랫폼 기축통화 ARTS 토큰</p>
                  <p>아트셋은 발행하는 유틸리티 토큰 ARTS(ERC-20)는 거래소에 상장되어 플랫폼의 모든 서비스에 사용됩니다.<br />플랫폼 활성화를 위해 ARTS 토큰은 사용자 참여도에 따른 차별화된 보상으로 제공되거나 토큰 에어드랍(Air-drop)에 사용됩니다.</p>
                </>
                :
                <>
                  <p>ARTS Token of Platform Key Currency</p>
                  <p>The utility token ARTS (ERC-20) issued by the Artxset is listed on the exchange and used for all services on the platform.
                  <br />For platform activation, the ARTS token is provided as a differentiated reward depending on the user participation or is used for token air-drop.</p>
                </>
            }
          </ScrollAnimation>
        </TitleBox>
        <Content>
          <div>
            <div>
              <p>ART TOKEN REWARD</p>
            </div>
            {
              language === "한국어" ?
                <p>사용자가 플렉스 소비에 최적화된 제품 및 서비스를 구매하면 예술품 소유권을 구매할 수 있는 유틸리티 토큰 ARTS를 보상으로 제공합니다. 아트셋 제휴 사업자는 아트 마케팅을 통해 브랜드 이미지를 제고하고 고객은 예술품 구매혜택을 통해 Flex 표출을 극대화할 수 있습니다.</p>
                :
                <p>When a user purchases products and services optimized for Flex consumption, he/she receives the 'ARTS' token that can purchase artwork ownership as a reward. Business operators can enhance their brand image through art marketing, and customers can maximize Flex expression through art purchase benefits.</p>
            }
          </div>
          <div>
            <div>
              <p>A-fun Reward System</p>
            </div>
            {
              language === "한국어" ?
                <p>아트셋은 ARTS 리워드 시스템과 에이락 리워드 시스템 두 가지 보상체제를 통해 유저 접근성을 극대화할 수 있습니다. 아트셋은 삼성SDS, 드림시큐리티와 가상자산 핀테크 리워드 시스템을 공동 개발한 ㈜에이락과의 기술 파트너십 계약을 체결했으며 제휴 결제시스템 유저는 약 1,000만 명에 달합니다.</p>
                :
                <p>ARTXSET can maximize user accessibility through two reward systems. We signed a technical partnership agreement with A-fun, which co-developed the virtual asset FinTech Reward System with SamsungSDS, DreamSecurity. There are about 10 million users of the A-fun alliance payment system.</p>
            }
          </div>
        </Content>
        <TitleBox style={{ marginTop: 360 }}>
          <ScrollAnimation animateIn='fadeInUp'>
            <h2>What can ARTXSET change?</h2>
          </ScrollAnimation>
          <div style={{ width: 160 }} />
        </TitleBox>
        <ChangeContent>
          <MChangeImg>
            <img alt="" src={change_phone} />
          </MChangeImg>
          <ChangeTextBox>
            <ScrollAnimation animateIn='fadeInRight'>
              <div>
                <img alt="" src={change_icon1} />
                {
                  language === "한국어" ?
                    <p>블록체인 기반 소유권 보장</p>
                    :
                    <p>Ownership Guarantee base on Blockchain</p>
                }
              </div>
              {
                language === "한국어" ?
                  <p>기존 예술자산의 토큰화(NFT)를 통해 소유권(계약정보)의 위변조 불가</p>
                  :
                  <p>It is Impossible to forge and falsify ownership (contract information) thanks to the tokenization (NFT) of existing artistic assets</p>
              }
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInRight' duration="1.25">
              <div>
                <img alt="" src={change_icon2} />
                {
                  language === "한국어" ?
                    <p>실물 소유권 거래가 가능한 NFT 마켓플레이스</p>
                    :
                    <p>NFT Market with Real Ownership Transactions</p>
                }
              </div>
              {
                language === "한국어" ?
                  <p>혁신적인 NFT 거래 프로토콜과 스마트 컨트랙트를 통해 실제 작품 뿐만 아니라 희소성 높은 실물의 소유권 NFT 거래 가능</p>
                  :
                  <p>Innovative NFT trading protocols and smart contracts can trade real-works and rare-tem ownership NFTs.</p>
              }
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInRight' duration="1.5">
              <div>
                <img alt="" src={change_icon3} />
                {
                  language === "한국어" ?
                    <p>아트라이프를 위한 토큰 이코노미</p>
                    :
                    <p>Token Economy for Art Life</p>
                }
              </div>
              {
                language === "한국어" ?
                  <p>플렉스 소비와 미디어를 연결한 차별화된 ARTS토큰 리워드 시스템을 통해 진정한 아트 라이프를 실현함으로써 New 아트마케팅 전략 제시</p>
                  :
                  <p>We present a new art marketing strategy by realizing a true art life through a differentiated ARTS token reward system that connects flex consumption and media.</p>
              }
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInRight' duration="1.75">
              <div>
                <img alt="" src={change_icon4} />
                {
                  language === "한국어" ?
                    <p>실제 예술품 NFT 밸류에이션 구축</p>
                    :
                    <p>Building NFT Valuation of Real Artworks</p>
                }
              </div>
              {
                language === "한국어" ?
                  <p>원작의 가격, NFT 거래량, 사용자 토큰 리워드 및 참여도, 어치버 비율, 경매현황 등의 데이터를 기반으로 NFT 밸류에이션 프로세스 구축</p>
                  :
                  <p>We build a work NFT valuation process based on the original price, NFT Transaction Volume, User participation according to ARTS token reward, Auction status etc.</p>
              }
            </ScrollAnimation>
          </ChangeTextBox>
          <ChangeImg>
            <img alt="" src={change_phone} />
          </ChangeImg>
        </ChangeContent>
        <Display>
          <h2>04</h2>
          <div />
          <h2>Art-tech</h2>
        </Display>
      </ContentsBox>
    </Section>
  );
};

export default TokenContainer;