import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';

import flex_bg from '../../Images/flex_bg.png';

const Section = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  min-height: 1000px;
  /* padding-top: 400px; 
  padding-bottom: 400px; */
  background-color: #000;
  background-image: url(${flex_bg});
  background-size: cover;
  background-position: center;

  @media (max-width: 800px) {
    padding-top: 120px; 
    padding-bottom: 300px;
  };
`
const ContentsBox = styled.div`
  position: relative;
  z-index: 100;
  width: 1280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1280px) {
    width: 100%;
    padding: 0 5%;
  };
`
const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div {
    & > h2 {
      font-size: 37px;
      color: #fff;
      font-weight: 500;
      text-align: center;
      line-height: 140%;
    }
  }
 
  @media (max-width: 1010px) {
    & > div {
      & > p {
        width: 100%;
      }
    }
  };
`
const FlexContainer = () => {

  return (
    <Section id='HOME'>
      <ContentsBox>
        <TitleBox>
          <ScrollAnimation animateIn='fadeIn'>
            <h2>Link Arttech to F.L.E.X Consumption</h2>
          </ScrollAnimation>
        </TitleBox>
      </ContentsBox>
    </Section>
  );
};

export default FlexContainer;