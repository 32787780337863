import React from 'react';
import { Route } from "react-router-dom";
import TemplatePage from './Pages/TemplatePage';

const Routes = () => {
  return (
    <div>
      <Route exact path="/" component={TemplatePage} />
    </div>
  );
};

export default Routes;