import React, { useState, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';

import HeaderLogoImg from './Images/logo_black.svg'
import HeaderLogoImgWhite from './Images/logo_white.svg'
import sidebar_img from './Images/sidebar_img.png'

let ScrollLink = Scroll.Link;

const HeaderAni = keyframes`
  0% {
    height: 0px
  }
  50% {
    height: 80px;
  }
  100% {
    height: 60px;
  }
`

const Section = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: ${props => props.PageBoolean ? (props.HeaderScollo ? "fixed" : "absolute") : "fixed"};
  top: 0;
  left: 0;
  height: ${props => props.PageBoolean ? (props.HeaderScollo ? "60px" : "80px") : "60px"};
  width: 100%;
  background-color: ${props => props.PageBoolean ? (props.HeaderScollo ? "#000" : "transparent") : "#000"};
  animation: ${props => props.PageBoolean ? (props.HeaderScollo ? css`${HeaderAni} 0.3s linear` : "none") : "none"};
  z-index: 150;
`

const SideNav = styled.div`
  display: ${props => props.pathname === "/clinics" ? "none" : "block"};
  margin-top: 4px;
  margin-left: 30px;
  cursor: pointer;

  & > div {
    height: 2px;
    width: 20px;
    border-radius: 2px;
    background-color: ${props => props.PageBoolean ? (props.HeaderScollo ? "#fff" : "#000") : "#fff"};;
  }
  & > div:nth-child(1){
    width: 23px;
  }
  & > div:nth-child(2){
    width: 17px;
  }
  & > div:nth-child(3){
    width: 20px;
  }

  div + div {
    margin-top: 7px;
  }

  @media (min-width: 768px) {
    display: none;
  };
`

const SectionBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1360px;

`

const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
  cursor: pointer;

  & a > img {
    height: 30px;
  }

  @media (max-width: 768px) {
    display: none;
  };
`

const TopMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: 30px;

  & > a {
    margin-left: 60px;
    cursor: pointer;

    & > a > p {
      color: ${props => props.PageBoolean ? (props.HeaderScollo ? "#fff" : "#000") : "#fff"};
      font-weight: 300;
      font-size: 13px;
      font-weight: 400;

      & > span {
        font-family: 'Abhaya Libre';
        font-weight: 400;
      }
    }
    
    &:first-child {
      margin-left: 0;
    }
  }

  & > .active {
    
    & > a > p {
      color: ${props => props.PageBoolean ? (props.HeaderScollo ? "#fff" : "#000") : "#fff"};
      font-size: 13px;
      font-weight: 600;

      & > span {
        font-family: 'Abhaya Libre';
        font-weight: 400;
      }
    }
  }

  @media (max-width: 768px) {
    display: none;
  };

  & > a > div:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 32px;
    border: 1px solid black;
    border-radius: 34px;
    font-size: 12px;
    font-weight: 300;

    & p {
      color: black;
    }
  }

  & > a > div:last-child:hover {
    background-color: black;

    & p {
      color: white;
    }
  }
`

const SideBox = styled.div`
  position: fixed;
  top:0;
  left:0;
  margin-left: ${props => props.SideMenu ? "0" : "-250px"};
  width: 250px;
  height: 100vh;
  background: #fff;
  /* background-image: url(${sidebar_img});
  background-size: cover;
  background-position: right; */
  box-shadow: ${props => props.SideMenu ? "4px 0px 4px rgba(0, 0, 0, 0.10)" : "none"};
  transition-property: margin-left;
  transition-duration: 0.5s;
  z-index: 200;

  @media (min-width: 768px) {
    display: none;
  };
`

const SideMenuBox = styled.div`
  margin-top: 50px;
  text-align: right;
  padding-right: 50px;
  
  & > div {
    margin-top:20px;
    height: 10px;
  }

  & > a {
    color: black;
    font-weight: 100;
    cursor: pointer;
    font-size: 21px;
   
   & > a > p {
     line-height: 70px;
     color: black;
     font-weight: bold;
     text-decoration-line: underline;

      & > span {
        font-family: 'Abhaya Libre';
      }
   }
  }

  & > .active {
    font-weight: 500;
  }
`

const SideBackground = styled.div`
  display: ${props => props.SideMenu ? "block" : "none"};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: 100;
`
const SelectBox = styled.div`
  margin-left: auto;
  position: relative;
  cursor: pointer;
  margin-right: 20px;

  @media (min-width: 768px) {
    display: none;
  };

  & > div:nth-child(1) {
    position: relative;
    display: flex;
    align-items: center;
    width: 100px;
    height: 24px;
    border-radius: 30px;
    background: #444;
    border: none;
    z-index: 30;

    &:after {
      position: absolute;
      content: "";
      top: 10px;
      right: 14px;
      width: 0;
      height: 0;
      border: 4px solid transparent;
      border-color: white transparent transparent transparent;
    }

    & > p {
      margin-left: 20px;
      font-size: 11px;
      color: white;
    }
  }
`

const PickSelectBox = styled.div`

  opacity: ${props => props.Translation ? "1" : "0" };
  z-index: ${props => props.Translation ? "50" : "10" };
  margin-top: ${props => props.Translation ? "32px" : "-200px" };
  position: absolute;
  top: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100px;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition-property: opacity, z-index, margin-top;
  transition-duration: 0.3s, 0.1s, 0.3s;

  & > p {
    line-height: 32px;
    font-size: 11px;
    color: #222;
    cursor: pointer;
    width: 100%;
    text-align: center;
  }
`

const Header = ({ setSideMenuHandler, SideMenu, language, Translation, TranslationHandler, KoreaLanguageHandler, EnglishLanguageHandler, pathname }) => {
  const [HeaderScollo, setHeaderScollo] = useState(false);
  const [PageBoolean, setPageBoolean] = useState(false);

  const PageBooleanHandler = () => {
    const PathName = window.location.pathname;
    if (PathName === "/") {
      setPageBoolean(true)
    } else {
      setPageBoolean(false)
    }
  }

  useEffect(() => {
    PageBooleanHandler()
    document.addEventListener('scroll', () => {
      const ScrollY = window.pageYOffset
      if (ScrollY > 80) {
        setHeaderScollo(true)
      } else {
        setHeaderScollo(false);
      };
    });
  }, []);

  return (
    <Section HeaderScollo={HeaderScollo} PageBoolean={PageBoolean}>
      <SectionBox>
        <SideNav onClick={setSideMenuHandler} pathname={pathname} PageBoolean={PageBoolean} HeaderScollo={HeaderScollo}>
          <div></div>
          <div></div>
          <div></div>
        </SideNav>
        <Logo>
          <ScrollLink
            activeClass="active"
            to="HOME"
            spy={true}
            smooth={true}
            offset={-60}
            duration={500}
          >
            <Link to="/">
              {
                PageBoolean && HeaderScollo ?
                <img alt="" src={HeaderLogoImgWhite} />
                :
                <img alt="" src={HeaderLogoImg} />
              }
            </Link>
          </ScrollLink>
        </Logo>
        <TopMenu PageBoolean={PageBoolean} HeaderScollo={HeaderScollo}>
          <ScrollLink
            activeClass="active"
            to="ABOUT"
            spy={true}
            smooth={true}
            offset={-60}
            duration={500}
          >
            <Link to="/">
              <p>ABOUT</p>
            </Link>
          </ScrollLink>
          <ScrollLink
            activeClass="active"
            to="PROTOCOL"
            spy={true}
            smooth={true}
            offset={-60}
            duration={500}
          >
            <Link to="/">
              <p>PROTOCOL</p>
            </Link>
          </ScrollLink>
          <ScrollLink
            activeClass="active"
            to="ART-TECH"
            spy={true}
            smooth={true}
            offset={-60}
            duration={500}
          >
            <Link to="/">
              <p>ART-TECH</p>
            </Link>
          </ScrollLink>
          <ScrollLink
            activeClass="active"
            to="VISION"
            spy={true}
            smooth={true}
            offset={-60}
            duration={500}
          >
            <Link to="/">
              <p>VISION</p>
            </Link>
          </ScrollLink>
        </TopMenu>
        <SelectBox>
          <div onClick={TranslationHandler}><p>{language}</p></div>
          <PickSelectBox Translation={Translation}>
            <p onClick={EnglishLanguageHandler}>English</p>
            <p onClick={KoreaLanguageHandler}>한국어</p>
          </PickSelectBox>
        </SelectBox>
      </SectionBox>
      <SideBox SideMenu={SideMenu}>
        <SideMenuBox>
          <ScrollLink
            activeClass="active"
            to="ABOUT"
            spy={true}
            smooth={true}
            offset={-60}
            duration={500}
          >
            <Link to="/">
              <p>HOME</p>
            </Link>
          </ScrollLink>
          <ScrollLink
            activeClass="active"
            to="ABOUT"
            spy={true}
            smooth={true}
            offset={-60}
            duration={500}
          >
            <Link to="/">
              <p>ABOUT</p>
            </Link>
          </ScrollLink>
          <ScrollLink
            activeClass="active"
            to="PROTOCOL"
            spy={true}
            smooth={true}
            offset={-60}
            duration={500}
          >
            <Link to="/">
              <p>PROTOCOL</p>
            </Link>
          </ScrollLink>
          <ScrollLink
            activeClass="active"
            to="ART-TECH"
            spy={true}
            smooth={true}
            offset={-60}
            duration={500}
          >
            <Link to="/">
              <p>ART-TECH</p>
            </Link>
          </ScrollLink>
          <ScrollLink
            activeClass="active"
            to="VISION"
            spy={true}
            smooth={true}
            offset={-60}
            duration={500}
          >
            <Link to="/">
              <p>VISION</p>
            </Link>
          </ScrollLink>
        </SideMenuBox>
      </SideBox>
      <SideBackground SideMenu={SideMenu} onClick={setSideMenuHandler}></SideBackground>
    </Section>
  );
};

export default Header; 