import React, { useState } from 'react';
import Template from '../../Components/Template/Template';
import MainContainer from '../ContentsContainer/MainContainer';
import AboutContainer from '../ContentsContainer/AboutContainer';
import AchieveContainer from '../ContentsContainer/AchieveContainer';
import ArtxetsContainer from '../ContentsContainer/ArtxetsContainer';
import TokenContainer from '../ContentsContainer/TokenContainer';
import BeyondContainer from '../ContentsContainer/BeyondContainer';
import LatestContainer from '../ContentsContainer/LatestContainer';
import FlexContainer from '../ContentsContainer/FlexContainer';
import CyclesContainer from '../ContentsContainer/CyclesContainer';
import RodmapContainer from '../ContentsContainer/RodmapContainer';
import GraphContainer from '../ContentsContainer/GraphContainer';
import PartnersContainer from '../ContentsContainer/PartnersContainer';
import Modal from '../../Components/Modal/Modal';
import useInput from '../../Components/Hooks/useInput';

const TemplateContainer = () => {
  const modal = useInput(false);
  const [language, setLanguage] = useState("한국어")

  return (
    <Template language={language} setLanguage={setLanguage}>
      <MainContainer language={language} />
      <div id='ABOUT'>
        <AboutContainer language={language} />
      </div>
      <div id='PROTOCOL'>
        <AchieveContainer language={language} />
      </div>
      <div id='ART-TECH'>
        <ArtxetsContainer language={language} />
        <FlexContainer language={language} />
        <TokenContainer language={language} />
        <CyclesContainer language={language} />
      </div>
      <div id='VISION'>
        <RodmapContainer language={language} />
        <GraphContainer language={language} />
        <PartnersContainer language={language} />
      </div>
      <BeyondContainer language={language} />
      <LatestContainer language={language} modal={modal}/>
      <Modal modal={modal} />
    </Template>
  )
}

export default TemplateContainer;