import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';

import dott_video from '../../video/video2.mp4';
import achieve_bg from '../../Images/achieve_bg.png';

import achieve_icon1 from '../../Images/achieve_icon1.svg';
import achieve_icon2 from '../../Images/change_icon2.svg';

const Section = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 240px; 
  padding-bottom: 300px;
  background-color: #000;

  & > video {
    position: absolute;
    top: 0;
    left: auto;
    height: 100%;
  }

  @media (max-width: 1640px) {
    & > video {
      width: auto;
      height: 100%;
    }
  };
  @media (max-width: 1200px) {
    & > video {
      display: none;
    }
  };
  @media (max-width: 800px) {
    padding-top: 20px; 
    padding-bottom: 200px;
  };
`
const ContentsBox = styled.div`
  position: relative;
  z-index: 100;
  width: 1280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  & > img {
    width: 221px;
  }

  & > h2 {
    text-align: center;
    margin-top: 50px;
    font-weight: bold;
    font-size: 51px;
    line-height: 140%;
  }

  @media (max-width: 1280px) {
    width: 100%;
    padding: 0 5%;
  };

  @media (max-width: 768px) {
    margin-top: 140px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > h2 {
      width: 400px;
      text-align: center;
    }
  };
`
const TitleBox = styled.div`
  width: 664px;

  & > div:nth-child(1) > h2 {
    font-size: 110px;
    font-weight: bold;
    color: #fff;
    & > span {
      font-family: 'Abhaya Libre';
      font-weight: 700;
    }
  }
  & > div:nth-child(2) {
    margin-top: 20px;
    width: 119px;
    height: 4px;
    background-color: #fff;
  }
  & > div:nth-child(3) {
    & > p:nth-child(1) {
      margin-top: 50px;
      line-height: 160%;
      font-size: 23px;
      font-weight: 700;
      color: #fff;
    }
    & > p:nth-child(2) {
      margin-top: 20px;
      line-height: 240%;
      color: #fff;
    }
  }

  @media (max-width: 1200px) {
    width: 100%;

    & > div:nth-child(1) > h2 {
      font-size: 57px;
    }
    & > div:nth-child(2) {
      width: 67px;
      height: 3px;
    }
  }
`
const BgImg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  & > img {
    height: 100%;
  }
`
const Button = styled.div`
  margin-right: auto;
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 270px;
  height: 54px;
  border: 1px solid #fff;
  cursor: pointer;

  &:hover {
    background-color: #fff;

    & > h2 {
      color: #000;
      font-weight: bold;
    }
  }

  & > h2 {
    font-size: 24px;
    color: #fff;
  }
`
const Display = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  opacity: 0.4;

  & > h2:nth-child(1) {
    font-size: 15px;
  }
  & > div {
    margin-top: 10px;
    height: 70px;
    width: 0.5px;
    background-color: #000;
  }
  & > h2:nth-child(3) {
    font-size: 11px;
    margin-top: 10px;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    & > span {
      font-family: 'Abhaya Libre';
      font-weight: 500;
    }
  }

  @media (max-width: 1200px) {
    display: none;
  };
`
const ChangeTextBox = styled.div`
  margin-top: 60px;

  & > div + div {
    margin-top: 40px;
  }

  & > div {
    width: 800px;

    & > div {
      display: flex;
      align-items: center;
      & > p {
        margin-left: 15px;
        font-size: 17px;
        line-height: 140%;
        font-weight: bold;
        color: #fff;
      }
    }
    & > p {
      margin-top: 20px;
      color: #fff;
      font-size: 15px;
      line-height: 200%;
      font-weight: 300;
    }
  }

  @media (max-width: 1200px) {
    width: 100%;

    & > div {
      width: 100%;
    }
  };
`

const AchieveContainer = ({ language }) => {

  return (
    <Section id='HOME'>
      <video
        autoPlay
        // loop
        muted={true}
        playbackRate={0.5}
        playsInline={true}
      >
        <source src={dott_video} type="video/mp4" />
      </video>
      <ContentsBox>
        <TitleBox>
          <ScrollAnimation animateIn='fadeInUp'>
            <h2><span>51’</span>Achieve</h2>
          </ScrollAnimation>
          <div />
          <ScrollAnimation animateIn='fadeIn' delay={500} duration="1.5">
            {
              language === "한국어" ?
                <>
                  <p>소유자 지정 및 권한부여 프로토콜</p>
                  <p>51’어치브 프로토콜은 예술품 지분을 분할하여 판매하는 공동구매에서 특정 개인 또는 단체가 NFT 발행량의 51% 이상을 구매할 경우 작품의 소유권을 구매자에게 이전하고 거래권한을 강화합니다. 이를 통해 기존 미술품 공동구매에서 거래이력이 생성되지 않는, 불분명한 소유권 문제를 해결하고 판매자 책임을 강화하여 거래 신뢰도를 높일 수 있습니다.</p>
                </>
                :
                <>
                  <p>Assign Owner and Authorization Protocol</p>
                  <p>The 51'Attached Protocol transfers ownership of the work to the buyer and strengthens trading rights when a particular individual or organization purchases more than 51% of NFT issuance. It can solve the unclear ownership issues that trading history is not created in conventional art group purchases and improve the reliability of the trading by enhancing the responsibility of the seller.</p>
                </>
            }
          </ScrollAnimation>
        </TitleBox>
        <ChangeTextBox>
          <ScrollAnimation animateIn='fadeInRight'>
            <div>
              <img alt="" src={achieve_icon1} style={{ width: 23, marginBottom: 4 }}/>
              {
                language === "한국어" ?
                  <p>어치버 작품 경매전환 권한 부여</p>
                  :
                  <p>Authorize the auction conversion</p>
              }
            </div>
            {
              language === "한국어" ?
                <p>홀더들을 대표하는 어치버는 작품의 소유권 NFT 거래의 판매기간을 연장하거나 온라인 경매로 전환할 수 있는 권한을 부여 받습니다.</p>
                :
                <p>Achiever, who represents the holders, is authorized to extend the sale period of the NFT transaction or convert it into an online auction.</p>
            }
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInRight' duration="1.25">
            <div>
              <img alt="" src={achieve_icon2} />
              {
                language === "한국어" ?
                  <p>어치버 수익비율 및 리워드</p>
                  :
                  <p>Rates and Rewards</p>
              }
            </div>
            {
              language === "한국어" ?
                <p>어치버가 NFT를 구매한 시점에서 온라인 경매 완료까지 NFT를 락업(Lock-up)할 경우 보유지분의 5% 내외에서 추가수익을 배당 받을 수 있습니다.</p>
                :
                <p>If an Achiever locks up the NFT until the auction is completed after purchasing the NFT, he can receive additional profits from around 5% of their holdings.</p>
            }
          </ScrollAnimation>
        </ChangeTextBox>
        <Button>
          <h2>Be the Achiever</h2>
        </Button>
        <Display>
          <h2>03</h2>
          <div />
          <h2><span>51’</span>Achieve</h2>
        </Display>
      </ContentsBox>
      <BgImg>
        <img alt="" src={achieve_bg} />
      </BgImg>
    </Section>
  );
};

export default AchieveContainer;