import React, { useState } from 'react';
import styled from 'styled-components';
import emailjs from 'emailjs-com';

const Section = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 160px; 
  background-color: #000;

  @media (max-width: 800px) {
    padding-top: 20px; 
  };
`
const ContentsBox = styled.div`
  position: relative;
  z-index: 100;
  width: 1280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1280px) {
    width: 100%;
    padding: 0 5%;
  };
`
const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div {
    & > h2 {
      font-size: 32px;
      color: #fff;
      font-weight: 500;
      text-align: center;
    }
  }
 
  @media (max-width: 1010px) {
    & > div {
      & > p {
        width: 100%;
      }
    }
  };
`
const Contents = styled.div`
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > form {
    display: flex;
    width: 460px;
    height: 40px;
    background-color: #fff;

    & > input {
      width: calc(100% - 100px);
      padding: 0 20px;
      border: none;
    }
    & > button {
      width: 100px;
      background-color: #222;
      color: #fff;
    }
  }

  @media (max-width: 500px) {
    & > form {
      width: 100%;
    }
  };
`
const Line = styled.div`
  margin-top: 200px;
  width: 1280px;
  border: 0.5px solid #fff;

  @media (max-width: 1320px) {
    width: 100%;
  };
`

const LatestContainer = ({ modal }) => {
  const [userEmail, setUserEmail] = useState("")

  const onSubmit = (e) => {
    e.preventDefault()// Prevents default refresh by the browser
    if(userEmail) {
      emailjs.sendForm('service_sqa1w7z', 'template_aj8y03u', e.target, 'user_Bs5B56nvBut07BbwFHX6r')
        .then(result => {
          modal.setValue(true);
          setUserEmail("");
        },
          error => {
            alert('An error occured, Plese try again', error.text)
          })
    }
  }

  return (
    <Section id='HOME'>
      <ContentsBox>
        <TitleBox>
          <div>
            <h2>Get the Latest News</h2>
          </div>
        </TitleBox>
        <Contents>
          <form onSubmit={onSubmit}>
            <input placeholder="Email Address" type="email" name="userEmail" onChange={(e) => { setUserEmail(e.target.value) }} value={userEmail} required></input>
            <button type='submit'>SEND</button>
          </form>
        </Contents>
        <Line />
      </ContentsBox>
    </Section>
  );
};

export default LatestContainer;