import React from 'react';
import styled from 'styled-components';

import dott_video from '../../video/video.mp4'
import mainLogo from '../../Images/main_logo.png'
import main_bg from '../../Images/main_bg.png'
import whitepaper_kor from '../../Whitepaper/whitepaper_kor.pdf'
import whitepaper_eng from '../../Whitepaper/whitepaper_eng.pdf'

const Section = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 1000px;
  overflow: hidden;
  /* background-image: url(${main_bg});
  background-size: cover;
  background-position: center; */

  & > video {
    position: absolute;
    top: 0;
    left: 0;
    width: 101%;
    /* opacity: 0.5; */
  }

  @media (max-width: 1640px) {
    & > video {
      width: auto;
      height: 100%;
    }
  };

  @media (max-width: 800px) {
    height: auto;
    padding-top: 80px; 
    padding-bottom: 120px;
  };
`
const MainBox = styled.div`
  position: relative;
  z-index: 100;
  width: 1280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > img {
    width: 221px;
  }

  & > h2 {
    text-align: center;
    margin-top: 50px;
    font-weight: bold;
    font-size: 51px;
    line-height: 140%;
  }

  @media (max-width: 1170px) {
    width: 100%;
    padding: 0 5%;
  };

  @media (max-width: 800px) {
    margin-top: 140px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > img {
      width: 160px;
    }

    & > h2 {
      font-size: 37px;
      text-align: center;
    }
  };
  @media (max-width: 500px) {
    & > h2 {
      width: auto;
    }
  };
`
const Button = styled.div`
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 61px;
  border: 2px solid #000;
  cursor: pointer;
  border-radius: 61px;

  & > a {
    color: #000;

    & > h2 {
      font-size: 23px;
      /* color: #fff; */
      font-weight: bold;
    }
  }

  &:hover {
    background-color: #000;

    & > a {
      & > h2 {
        color: #fff;
        font-weight: normal;
      }
    }
  }

  @media (max-width: 500px) {
    width: 280px;
    height: 57px;
    border-radius: 57px;

    & > h2 {
      font-size: 23px;
    }
  }
`

const MainContainer = ({ language }) => {

  return (
    <Section id='HOME'>
      <video
        autoPlay
        // loop
        muted={true}
        playbackRate={0.5}
        playsInline={true}
      >
        <source src={dott_video} type="video/mp4" />
      </video>
      <MainBox>
        <img art="" src={mainLogo}/>
        <h2>{'Achieve and Collect The Real Artwork NFT'}</h2>
        <Button>
          <a href={ language === "한국어" ? whitepaper_kor : whitepaper_eng } target="_blank">
            <h2>Whitepaper</h2>
          </a>
        </Button>
      </MainBox>
    </Section>
  );
};

export default MainContainer;